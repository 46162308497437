import React from 'react';
import ChangeBackground from '../components/ChangeBackground';
import { Layout, SEO } from '../layout';

const IndexPage = () => {
	return (
		<>
			<Layout>
				<SEO title="Home" />
				<section >
					<div className="images_home" >
						<ChangeBackground />
					</div>
				</section>
			</Layout>
		</>
	);
};

export default IndexPage;
