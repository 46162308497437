import React from "react";
import { useState, useEffect, useRef } from "react";

export default function ChangeBackground() {
  const img0 = "../images/IMG_8373.jpg";
  const img1 = "../images/IMG_3340.jpg";
  const img2 = "../images/image_home4.JPG";
  const img3 = "../images/IMG_9946.JPG";
  const img4 = "../images/IMG_20190307_142218.jpg";
  const img5 = "../images/IMG_0113.JPG";
  const img6 = "../images/IMG_2072.JPG";
  const img7 = "../images/IMG_20190301_052811.jpg";
  const img8 = "../images/IMG_0130.JPG";
  const img9 = "../images/IMG_8283.jpg";
  const img10 = "../images/IMG_8359.jpg";

  let [lsNum, setLsNum] = useState(0);
  // Absolute number '1'
  let count = useRef(1);

  const list = [img0, img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];

  useEffect(() => {
    // Get value from localStorage, transform to number
    const lS = Number(localStorage.getItem("image"));
    // Check! If localStorage have number 2 / more
    // Set number 0
    if (lS >= 9) {
      localStorage.setItem("image", 0);
      return;
    }
    // Get absolute number and increase with number from localStorage
    count.current = count.current + lS;
    // Set result to state
    setLsNum(count.current);
    // Set the resulting number to localStorage
    localStorage.setItem("image", count.current);
  }, []);

  const css = {
    height: "38rem",
    width: "85%",
    margin: "0 auto",
    display: "block",
    backgroundImage: `url(${list[lsNum]})`, // index change
    backgroundPosition: "center",
    backgroundSize: "cover",
    "@media(maxWidth: 600px)": {
      width: "100%",
    }
  };

  return (
    <div className="App">
      <div style={css}></div>
    </div>
  );
}